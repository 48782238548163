package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.client.components.graphing.CouplingResponsiveLineProps
import com.zegreatrob.coupling.model.PlayerPair
import com.zegreatrob.coupling.model.party.PartyDetails
import com.zegreatrob.coupling.model.player.Player
import kotlin.Int
import kotlin.collections.List
import kotlin.time.Duration
import react.ElementType
import react.FC
import react.Key
import react.ReactNode
import react.create

public operator fun PartyStatisticsProps.component1(): PartyDetails = party

public operator fun PartyStatisticsProps.component2(): List<Player> = players

public operator fun PartyStatisticsProps.component3(): List<PlayerPair> = pairs

public operator fun PartyStatisticsProps.component4(): Int = spinsUntilFullRotation

public operator fun PartyStatisticsProps.component5(): Duration? = medianSpinDuration

public operator fun PartyStatisticsProps.component6(): FC<CouplingResponsiveLineProps>? = chartComponent

public operator fun PartyStatisticsProps.component7(): Key? = key

public fun ElementType<PartyStatisticsProps>.create(
  party: PartyDetails,
  players: List<Player>,
  pairs: List<PlayerPair>,
  spinsUntilFullRotation: Int,
  medianSpinDuration: Duration? = null,
  chartComponent: FC<CouplingResponsiveLineProps>? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.party = party
  this.players = players
  this.pairs = pairs
  this.spinsUntilFullRotation = spinsUntilFullRotation
  medianSpinDuration?.let { this.medianSpinDuration = it }
  chartComponent?.let { this.chartComponent = it }
  key?.let { this.key = it }
         }
}
