package com.zegreatrob.coupling.client.components.graphing.`external`.nivo

import js.objects.jso
import kotlin.Any

public operator fun NivoPoint.component1(): Any? = x

public operator fun NivoPoint.component2(): Any? = y

public operator fun NivoPoint.component3(): Any? = context

public fun NivoPoint(
  x: Any? = null,
  y: Any? = null,
  context: Any? = null,
): NivoPoint {
    return jso<NivoPoint> {
         x?.let { this.x = it }
  y?.let { this.y = it }
  context?.let { this.context = it }
         }
}
