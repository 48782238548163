package com.zegreatrob.coupling.client.components.stats

import com.zegreatrob.coupling.client.components.graphing.CouplingResponsiveLineProps
import com.zegreatrob.coupling.model.PlayerPair
import com.zegreatrob.coupling.model.player.Player
import kotlin.Int
import kotlin.collections.List
import kotlin.time.Duration
import react.ElementType
import react.FC
import react.Key
import react.ReactNode
import react.create

public operator fun PartyStatisticsContentProps.component1(): Int = spinsUntilFullRotation

public operator fun PartyStatisticsContentProps.component2(): List<Player> = players

public operator fun PartyStatisticsContentProps.component3(): Duration? = medianSpinDuration

public operator fun PartyStatisticsContentProps.component4(): List<PlayerPair> = pairs

public operator fun PartyStatisticsContentProps.component5(): FC<CouplingResponsiveLineProps>? = chartComponent

public operator fun PartyStatisticsContentProps.component6(): Key? = key

public fun ElementType<PartyStatisticsContentProps>.create(
  spinsUntilFullRotation: Int,
  players: List<Player>,
  medianSpinDuration: Duration? = null,
  pairs: List<PlayerPair>,
  chartComponent: FC<CouplingResponsiveLineProps>? = null,
  key: Key? = null,
): ReactNode {
   return create {
         this.spinsUntilFullRotation = spinsUntilFullRotation
  this.players = players
  medianSpinDuration?.let { this.medianSpinDuration = it }
  this.pairs = pairs
  chartComponent?.let { this.chartComponent = it }
  key?.let { this.key = it }
         }
}
